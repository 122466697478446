<script setup lang="ts">
import { useElementSize, useWindowSize } from "@vueuse/core";

const underconstruction = `<svg width="769" height="321" viewBox="0 0 769 321" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_329_251)">
<path class="smoke" d="M228.78 264.301C226.136 283.949 216.415 301.924 206.044 319.24C205.701 319.83 205.348 320.41 204.986 321H97.1963C95.0163 320.463 92.8644 319.874 90.7216 319.24C78.547 315.724 67.0533 310.361 56.6871 303.359C17.0312 276.259 1.77158 225.051 8.664 179.166C12.9497 150.658 26.1403 121.552 51.8261 106.199C65.3881 98.0775 82.8087 95.438 98.4207 98.4559C98.8292 98.5263 99.2373 98.6143 99.6545 98.6934C113.086 101.562 125.053 108.689 131.862 120.171C144.106 140.804 136.815 166.364 130.795 189.328C124.793 212.293 121.537 239.78 138.958 256.717C133.207 240.66 138.884 221.505 152.622 210.647C163.002 202.456 177.454 199.385 190.636 201.928C191.897 202.183 193.141 202.473 194.365 202.825C197.324 203.647 200.177 204.781 202.871 206.204C223.418 217.088 231.739 242.155 228.78 264.301Z" fill="#F2F2F2"/>
<path d="M164.867 319.24C167.853 319.9 170.85 320.481 173.864 321H157.566C155.432 320.454 153.308 319.874 151.203 319.24C146.574 317.868 142.016 316.293 137.529 314.515C119.534 307.353 102.957 296.601 90.4154 282.295C84.2818 275.363 79.2731 267.602 75.5549 259.269C71.523 249.849 68.9606 239.923 67.9486 229.802C65.6758 209.037 67.9948 187.7 72.3268 167.305C76.7973 146.167 84.0136 125.635 93.8107 106.181C94.9888 103.849 96.207 101.532 97.4655 99.2301C97.552 99.0406 97.6831 98.8723 97.8486 98.7381C98.0142 98.6039 98.2098 98.5074 98.4207 98.4558C98.8489 98.3771 99.2926 98.4625 99.6545 98.6934C100.089 98.9298 100.411 99.3154 100.554 99.7706C100.698 100.226 100.651 100.716 100.425 101.139C90.3233 119.824 82.6662 139.609 77.6235 160.055C72.6887 180.002 69.8872 200.872 70.8425 221.382C71.7515 240.545 77.0301 259.392 89.0054 275.062C99.9421 289.369 115.044 300.649 131.75 308.33C142.325 313.129 153.433 316.788 164.867 319.24V319.24Z" fill="white"/>
<path d="M200.636 321H195.831C195.293 320.419 194.773 319.83 194.263 319.24C183.318 306.627 175.903 291.599 172.705 275.546C168.82 255.514 171.842 234.827 181.322 216.551C183.967 211.427 187.084 206.533 190.636 201.928C191.897 202.183 193.141 202.473 194.365 202.825C185.022 214.566 178.738 228.236 176.026 242.718C173.606 256.289 174.41 270.202 178.381 283.44C182.351 296.679 189.387 308.909 198.975 319.24C199.513 319.83 200.07 320.419 200.636 321Z" fill="white"/>
<path class="smoke" d="M633.502 306.036C634.199 311.222 636.765 315.966 639.502 320.536C639.593 320.691 639.686 320.844 639.781 321H668.229C668.804 320.858 669.372 320.703 669.938 320.536C673.151 319.608 676.184 318.192 678.92 316.344C689.386 309.192 693.413 295.677 691.594 283.567C690.463 276.044 686.982 268.362 680.203 264.31C676.522 262.183 672.13 261.454 667.906 262.267C667.798 262.285 667.69 262.308 667.58 262.329C665.822 262.652 664.156 263.323 662.691 264.3C661.226 265.277 659.995 266.537 659.08 267.998C655.849 273.443 657.773 280.189 659.362 286.25C660.946 292.31 661.805 299.565 657.207 304.035C657.881 302.165 658.018 300.159 657.604 298.223C657.19 296.287 656.239 294.49 654.85 293.018C653.462 291.546 651.685 290.45 649.702 289.845C647.719 289.24 645.602 289.147 643.569 289.575C643.236 289.642 642.908 289.719 642.584 289.812C641.803 290.029 641.05 290.328 640.339 290.703C634.917 293.576 632.721 300.192 633.502 306.036Z" fill="#F2F2F2"/>
<path d="M650.369 320.536C649.581 320.71 648.79 320.863 647.994 321H652.295C652.859 320.856 653.419 320.703 653.975 320.536C655.197 320.173 656.4 319.758 657.584 319.289C662.386 317.439 666.651 314.523 670.018 310.785C671.637 308.956 672.959 306.907 673.94 304.708C675.004 302.222 675.68 299.602 675.947 296.931C676.435 291.409 676.046 285.849 674.792 280.437C673.612 274.858 671.707 269.44 669.122 264.305C668.811 263.69 668.489 263.079 668.157 262.471C668.134 262.421 668.1 262.376 668.056 262.341C668.012 262.306 667.961 262.28 667.905 262.267C667.792 262.246 667.675 262.268 667.579 262.329C667.465 262.392 667.38 262.493 667.342 262.614C667.304 262.734 667.316 262.863 667.376 262.975C670.042 267.906 672.063 273.128 673.394 278.524C674.765 283.816 675.367 289.262 675.184 294.709C675.086 299.775 673.419 304.702 670.39 308.876C667.445 312.635 663.571 315.65 659.109 317.656C656.318 318.923 653.386 319.889 650.369 320.536Z" fill="white"/>
<path d="M640.929 321H642.197C642.339 320.847 642.476 320.691 642.611 320.536C645.499 317.207 647.456 313.24 648.3 309.004C649.325 303.717 648.528 298.257 646.026 293.434C645.328 292.082 644.505 290.79 643.568 289.575C643.235 289.642 642.907 289.719 642.584 289.812C645.049 292.91 646.708 296.518 647.424 300.34C648.062 303.922 647.85 307.593 646.802 311.087C645.754 314.581 643.897 317.809 641.367 320.536C641.225 320.691 641.078 320.847 640.929 321Z" fill="white"/>
<path d="M377.088 186.978H288.054L287.11 161.013C287.069 159.887 287.286 158.767 287.745 157.728C288.205 156.69 288.896 155.758 289.772 154.997C290.647 154.236 291.686 153.665 292.817 153.321C293.948 152.978 295.143 152.871 296.322 153.007L348.134 159.025H348.14C355.822 159.045 363.181 161.952 368.607 167.109C374.033 172.266 377.083 179.253 377.088 186.539V186.978Z" fill="url(#paint0_linear_329_251)"/>
<path d="M337.405 94.875H275.308C274.482 94.8748 273.665 95.0289 272.902 95.3286C272.139 95.6283 271.445 96.0676 270.861 96.6215C270.277 97.1754 269.814 97.833 269.498 98.5568C269.182 99.2805 269.02 100.056 269.02 100.84V159.74C269.02 160.523 269.182 161.299 269.498 162.022C269.814 162.746 270.277 163.404 270.861 163.958C271.445 164.511 272.139 164.951 272.902 165.25C273.665 165.55 274.482 165.704 275.308 165.704H337.405C338.231 165.704 339.049 165.55 339.812 165.25C340.575 164.951 341.268 164.511 341.852 163.958C342.436 163.404 342.9 162.746 343.216 162.022C343.531 161.299 343.694 160.523 343.694 159.74V100.84C343.694 100.056 343.531 99.2805 343.216 98.5568C342.9 97.833 342.436 97.1754 341.852 96.6215C341.268 96.0676 340.575 95.6283 339.812 95.3286C339.049 95.0289 338.231 94.8748 337.405 94.875V94.875Z" fill="#CCCCCC"/>
<path d="M323.42 138.918L294.102 144.333C292.362 144.653 290.561 144.305 289.092 143.366C287.623 142.427 286.607 140.973 286.266 139.324L284.013 128.347C283.676 126.697 284.043 124.989 285.033 123.595C286.023 122.202 287.555 121.238 289.294 120.915L318.612 115.5C320.351 115.18 322.153 115.528 323.622 116.468C325.091 117.407 326.107 118.86 326.448 120.509L328.701 131.486C329.038 133.136 328.671 134.845 327.681 136.238C326.691 137.631 325.159 138.595 323.42 138.918Z" fill="#3F3D56"/>
<path d="M317.554 125.949L295.179 130.082C294.77 130.158 294.346 130.076 294 129.855C293.654 129.634 293.415 129.292 293.336 128.904C293.256 128.515 293.342 128.113 293.575 127.785C293.808 127.457 294.169 127.231 294.578 127.155L316.953 123.022C317.362 122.947 317.787 123.028 318.132 123.249C318.478 123.47 318.717 123.813 318.797 124.201C318.876 124.589 318.79 124.991 318.557 125.319C318.324 125.647 317.963 125.874 317.554 125.949V125.949Z" fill="white"/>
<path d="M313.205 132.07L301.632 134.207C301.223 134.283 300.799 134.201 300.453 133.98C300.107 133.759 299.868 133.417 299.789 133.029C299.709 132.641 299.795 132.238 300.028 131.91C300.261 131.582 300.622 131.356 301.031 131.28L312.605 129.142C312.807 129.105 313.016 129.106 313.218 129.145C313.42 129.184 313.612 129.26 313.784 129.37C313.955 129.479 314.102 129.619 314.216 129.783C314.33 129.946 314.409 130.129 314.448 130.321C314.488 130.513 314.487 130.711 314.446 130.903C314.405 131.095 314.324 131.277 314.209 131.44C314.093 131.602 313.945 131.741 313.773 131.849C313.601 131.957 313.408 132.032 313.205 132.07Z" fill="white"/>
<path d="M343.694 100.84V108.668H269.02V100.84C269.02 100.056 269.182 99.2805 269.498 98.5568C269.814 97.833 270.277 97.1754 270.861 96.6215C271.445 96.0676 272.139 95.6283 272.902 95.3286C273.665 95.0289 274.482 94.8748 275.308 94.875H337.405C338.231 94.8748 339.049 95.0289 339.812 95.3286C340.575 95.6283 341.268 96.0676 341.852 96.6215C342.436 97.1754 342.9 97.833 343.216 98.5568C343.531 99.2805 343.694 100.056 343.694 100.84Z" fill="#B3B3B3"/>
<path d="M492.81 111.119C492.379 109.799 491.648 108.585 490.668 107.562C489.689 106.539 488.485 105.732 487.141 105.198C485.798 104.664 484.347 104.416 482.891 104.472C481.436 104.527 480.01 104.885 478.716 105.519L393.285 76.446L389.522 96.6893L474.681 118.902C475.949 120.893 477.943 122.375 480.285 123.065C482.628 123.756 485.156 123.607 487.39 122.648C489.625 121.689 491.411 119.985 492.409 117.86C493.408 115.736 493.551 113.337 492.81 111.119V111.119Z" fill="#FFB8B8"/>
<path opacity="0.2" d="M492.81 111.119C492.379 109.799 491.648 108.585 490.668 107.562C489.689 106.539 488.485 105.732 487.141 105.198C485.798 104.664 484.347 104.416 482.891 104.472C481.436 104.527 480.01 104.885 478.716 105.519L393.285 76.446L389.522 96.6893L474.681 118.902C475.949 120.893 477.943 122.375 480.285 123.065C482.628 123.756 485.156 123.607 487.39 122.648C489.625 121.689 491.411 119.985 492.409 117.86C493.408 115.736 493.551 113.337 492.81 111.119V111.119Z" fill="black"/>
<path d="M408.449 80.1349L400.382 100.126C400.152 100.694 399.796 101.21 399.338 101.636C398.879 102.063 398.329 102.39 397.725 102.597C397.121 102.804 396.477 102.885 395.838 102.834C395.198 102.784 394.577 102.603 394.017 102.304L374.411 91.8369C371.35 90.7063 368.886 88.4705 367.56 85.6197C366.234 82.7689 366.154 79.5358 367.337 76.629C368.521 73.7223 370.871 71.3793 373.873 70.1135C376.874 68.8478 380.283 68.7627 383.351 69.8768L405.245 74.4826C405.871 74.6146 406.461 74.8733 406.973 75.2409C407.485 75.6085 407.907 76.0764 408.211 76.6129C408.515 77.1493 408.694 77.7415 408.735 78.3493C408.776 78.9571 408.678 79.5662 408.449 80.1349V80.1349Z" fill="url(#paint1_linear_329_251)"/>
<path d="M499.534 129.629L468.923 142.827L472.942 157.179C480.703 184.892 482.763 213.765 479.008 242.218V242.218L501.39 246.651L519.942 201.778L513.449 150.746L499.534 129.629Z" fill="url(#paint2_linear_329_251)"/>
<path d="M559.598 196.059C539.485 196.059 523.095 209.928 522.299 227.294H596.897C596.102 209.928 579.711 196.059 559.598 196.059Z" fill="#3F3D56"/>
<path d="M543.941 199.052C542.895 120.658 495.824 127.87 495.824 127.87C495.824 127.87 496.705 152.271 497.128 153.03C527.123 206.86 477.62 269.967 414.606 258.358C413.418 258.139 412.277 257.917 411.182 257.692C403.198 256.11 396.148 251.704 391.468 245.368C386.787 239.032 384.826 231.244 385.985 223.585C392.192 176.305 361.319 177.142 361.319 177.142H316.175L292.73 157.129C291.903 156.422 290.905 155.919 289.825 155.663C288.745 155.406 287.616 155.405 286.535 155.659C285.454 155.912 284.455 156.413 283.626 157.117C282.796 157.822 282.161 158.708 281.776 159.699L278.76 167.464C278.76 167.464 234.234 171.863 237.945 213.217H251.711C251.677 214.392 251.727 215.567 251.859 216.736L354.594 215.944C360.616 215.897 365.932 220.526 365.957 226.238C365.963 227.629 365.673 229.006 365.105 230.288C364.537 231.57 363.702 232.73 362.651 233.699C361.599 234.668 360.352 235.426 358.984 235.927C357.617 236.429 356.157 236.663 354.691 236.617L338.128 236.093C333.026 257.65 345.549 272.167 359.348 281.296C372.339 289.766 387.755 294.257 403.519 294.164H448.515C506.956 294.164 517.16 247.531 517.16 247.531C547.771 235.213 543.941 199.052 543.941 199.052Z" fill="#3F3D56"/>
<path d="M556.815 319.68C585.633 319.68 608.994 297.522 608.994 270.188C608.994 242.854 585.633 220.696 556.815 220.696C527.997 220.696 504.636 242.854 504.636 270.188C504.636 297.522 527.997 319.68 556.815 319.68Z" fill="#3F3D56"/>
<path d="M556.815 282.318C563.878 282.318 569.604 276.887 569.604 270.188C569.604 263.488 563.878 258.057 556.815 258.057C549.752 258.057 544.026 263.488 544.026 270.188C544.026 276.887 549.752 282.318 556.815 282.318Z" fill="white"/>
<path d="M293.37 319.68C326.542 319.68 353.434 294.173 353.434 262.709C353.434 231.245 326.542 205.738 293.37 205.738C260.198 205.738 233.307 231.245 233.307 262.709C233.307 294.173 260.198 319.68 293.37 319.68Z" fill="#3F3D56"/>
<path d="M293.37 276.672C301.5 276.672 308.091 270.421 308.091 262.709C308.091 254.997 301.5 248.745 293.37 248.745C285.239 248.745 278.648 254.997 278.648 262.709C278.648 270.421 285.239 276.672 293.37 276.672Z" fill="white"/>
<path d="M469.01 146.727L457.996 118.352C457.422 116.873 457.161 115.302 457.227 113.727C457.293 112.152 457.686 110.605 458.383 109.175C459.079 107.744 460.066 106.458 461.287 105.389C462.508 104.32 463.938 103.489 465.497 102.945L497.074 91.9191C499.817 90.961 502.732 90.5247 505.653 90.6352C508.574 90.7457 511.444 91.4009 514.098 92.5633C516.752 93.7257 519.139 95.3725 521.122 97.4098C523.105 99.4471 524.646 101.835 525.656 104.437C526.566 106.772 527.032 109.243 527.03 111.733C527.018 114.895 526.263 118.015 524.821 120.865C523.379 123.716 521.285 126.226 518.692 128.213C516.819 129.638 514.714 130.764 512.458 131.547L469.01 146.727Z" fill="#3F3D56"/>
<path d="M526.566 111.733C526.563 114.831 525.828 117.888 524.416 120.681C523.003 123.473 520.948 125.93 518.402 127.87C514.496 126.893 510.862 125.12 507.751 122.676C504.64 120.232 502.128 117.175 500.389 113.716C498.65 110.258 497.726 106.482 497.681 102.651C497.636 98.82 498.471 95.0255 500.128 91.5314C503.31 90.8751 506.606 90.9031 509.775 91.6132C512.944 92.3234 515.905 93.6977 518.44 95.6352C520.976 97.5728 523.022 100.024 524.428 102.81C525.834 105.596 526.564 108.645 526.566 111.733V111.733Z" fill="url(#paint3_linear_329_251)"/>
<path d="M245.829 218.936C253.002 218.936 258.816 212.239 258.816 203.978C258.816 195.717 253.002 189.02 245.829 189.02C238.657 189.02 232.843 195.717 232.843 203.978C232.843 212.239 238.657 218.936 245.829 218.936Z" fill="url(#paint4_linear_329_251)"/>
<path d="M417.496 256.925H428.869L434.28 228.948L417.495 228.949L417.496 256.925Z" fill="#FFB8B8"/>
<path d="M414.595 253.403L436.992 253.402H436.993C438.867 253.402 440.723 253.752 442.455 254.433C444.186 255.113 445.76 256.11 447.085 257.367C448.41 258.624 449.462 260.117 450.179 261.759C450.896 263.402 451.265 265.162 451.265 266.94V267.38L414.596 267.381L414.595 253.403Z" fill="#2F2E41"/>
<path d="M430.697 245.442C430.564 245.442 430.431 245.436 430.298 245.424L414.559 244.337C413.923 244.281 413.308 244.087 412.763 243.77C412.218 243.454 411.758 243.022 411.416 242.51C411.075 241.997 410.862 241.417 410.794 240.814C410.727 240.211 410.806 239.601 411.026 239.032L432.089 194.146C432.239 193.746 432.301 193.321 432.27 192.897C432.238 192.473 432.115 192.06 431.907 191.684C431.699 191.303 431.409 190.968 431.056 190.701C430.702 190.434 430.293 190.242 429.856 190.135C419.951 187.68 394.539 181.141 372.541 173.49C363.117 170.212 357.181 165.475 354.899 159.411C351.89 151.415 356.341 144.124 356.532 143.818L356.681 143.578L377.381 145.363L399.821 147.173L448.998 172.186C453.118 174.299 456.248 177.808 457.764 182.012C459.28 186.216 459.071 190.808 457.178 194.872L434.506 243.096C434.178 243.794 433.644 244.387 432.968 244.804C432.292 245.22 431.503 245.442 430.697 245.442Z" fill="#2F2E41"/>
<path d="M380.98 56.5586C393.563 56.5586 403.764 46.8833 403.764 34.9483C403.764 23.0132 393.563 13.3379 380.98 13.3379C368.397 13.3379 358.197 23.0132 358.197 34.9483C358.197 46.8833 368.397 56.5586 380.98 56.5586Z" fill="#FFB8B8"/>
<path d="M411.003 259.564H422.376L427.787 231.587L411.001 231.588L411.003 259.564Z" fill="#FFB8B8"/>
<path d="M408.102 256.043L430.498 256.042H430.499C432.374 256.042 434.23 256.392 435.961 257.072C437.693 257.753 439.266 258.75 440.592 260.007C441.917 261.264 442.969 262.756 443.686 264.399C444.403 266.041 444.772 267.802 444.772 269.58V270.019L408.103 270.021L408.102 256.043Z" fill="#2F2E41"/>
<path d="M424.204 248.082C424.071 248.082 423.938 248.076 423.805 248.064L408.066 246.977C407.43 246.921 406.815 246.727 406.27 246.41C405.725 246.093 405.264 245.662 404.923 245.149C404.581 244.636 404.369 244.056 404.301 243.453C404.233 242.851 404.313 242.241 404.533 241.672L425.596 196.786C425.746 196.386 425.808 195.96 425.776 195.536C425.745 195.113 425.621 194.699 425.414 194.323C425.206 193.942 424.916 193.608 424.563 193.341C424.209 193.074 423.8 192.881 423.362 192.775C413.458 190.319 388.046 183.78 366.048 176.129C356.623 172.851 350.688 168.114 348.406 162.05C345.397 154.055 349.848 146.763 350.039 146.457L350.188 146.218L370.888 148.002L393.328 149.813L442.504 174.825C446.625 176.939 449.755 180.447 451.271 184.651C452.787 188.856 452.578 193.447 450.685 197.512L428.013 245.735C427.685 246.433 427.15 247.027 426.475 247.443C425.799 247.86 425.01 248.081 424.204 248.082Z" fill="#2F2E41"/>
<path d="M388.007 68.8128L363.888 60.894C363.888 60.894 348.748 71.9275 356.021 99.2988C359.819 113.423 358.712 128.333 352.867 141.814C352.164 143.482 351.352 145.107 350.438 146.681C350.438 146.681 377.339 177.476 402.385 154.6L392.645 110.166C392.645 110.166 407.951 80.691 388.007 68.8128Z" fill="url(#paint5_linear_329_251)"/>
<path d="M383.809 57.2937C380.566 52.69 378.007 46.3064 381.577 41.9261C385.101 37.6023 392.052 38.2393 397.449 36.3261C404.967 33.6612 409.326 25.2844 407.967 17.7823C406.607 10.2803 400.258 4.06137 392.694 1.51678C385.131 -1.02782 376.601 -0.200254 369.195 2.73C360.077 6.33718 352.255 13.315 348.975 22.1541C345.696 30.9932 347.449 41.6062 354.253 48.4001C361.548 55.6839 372.996 57.6251 383.587 57.5073" fill="#2F2E41"/>
<path d="M362.615 18.0231C358.529 21.1782 352.295 19.7768 347.909 17.0079C343.523 14.2389 339.964 10.296 335.218 8.12504C326.852 4.29767 316.737 6.84199 308.594 11.0815C300.451 15.321 293.233 21.1779 284.632 24.5048C276.031 27.8317 265.056 27.9962 258.543 21.7142C256.94 26.1187 256.809 30.8849 258.168 35.3626C259.526 39.8403 262.308 43.8113 266.134 46.734C269.96 49.6566 274.643 51.3885 279.545 51.6932C284.447 51.998 289.328 50.8609 293.524 48.437C302.948 42.992 308.153 31.5755 318.724 28.5703C324.573 26.9072 330.853 28.2466 336.765 29.6974C342.677 31.1481 348.841 32.7157 354.806 31.4807C360.772 30.2456 366.353 25.1605 365.594 19.4135L362.615 18.0231Z" fill="#2F2E41"/>
<path d="M491.676 110.239C491.076 108.98 490.193 107.86 489.088 106.96C487.983 106.059 486.684 105.399 485.281 105.027C483.878 104.654 482.406 104.578 480.968 104.803C479.531 105.029 478.163 105.55 476.962 106.331L388.402 87.4783L387.301 108.007L474.699 120.084C476.216 121.912 478.387 123.149 480.801 123.56C483.215 123.971 485.705 123.528 487.797 122.314C489.89 121.101 491.441 119.201 492.156 116.976C492.87 114.75 492.7 112.353 491.676 110.239V110.239Z" fill="#FFB8B8"/>
<path d="M403.929 89.3641L398.524 110.146C398.37 110.738 398.084 111.29 397.684 111.767C397.285 112.244 396.782 112.634 396.21 112.91C395.637 113.185 395.009 113.341 394.367 113.366C393.726 113.39 393.086 113.284 392.492 113.053L371.674 104.96C368.487 104.198 365.749 102.268 364.06 99.5938C362.372 96.9192 361.871 93.7186 362.667 90.6942C363.464 87.6698 365.493 85.0686 368.309 83.4615C371.125 81.8543 374.498 81.3724 377.689 82.1216L400.014 84.1299C400.654 84.1876 401.272 84.3753 401.828 84.6801C402.384 84.985 402.864 85.4 403.235 85.8967C403.607 86.3934 403.861 86.9603 403.981 87.5586C404.101 88.157 404.083 88.7728 403.929 89.3641V89.3641Z" fill="url(#paint6_linear_329_251)"/>
<path d="M481.445 126.55C485.032 126.55 487.939 123.793 487.939 120.391C487.939 116.989 485.032 114.232 481.445 114.232C477.859 114.232 474.952 116.989 474.952 120.391C474.952 123.793 477.859 126.55 481.445 126.55Z" fill="url(#paint7_linear_329_251)"/>
<path d="M769 320.12C768.999 320.353 768.901 320.577 768.727 320.741C768.554 320.906 768.318 320.999 768.072 321H0.927624C0.681603 321 0.445657 320.907 0.271694 320.742C0.0977308 320.577 0 320.353 0 320.12C0 319.887 0.0977308 319.663 0.271694 319.498C0.445657 319.333 0.681603 319.24 0.927624 319.24H768.072C768.318 319.241 768.554 319.334 768.727 319.499C768.901 319.664 768.999 319.887 769 320.12V320.12Z" fill="#3F3D56"/>
</g>
<defs>
<linearGradient id="paint0_linear_329_251" x1="287.75" y1="172.611" x2="391.79" y2="172.143" gradientUnits="userSpaceOnUse">
<stop stop-color="#FD6727"/>
<stop offset="0.84375" stop-color="#FD9B27"/>
</linearGradient>
<linearGradient id="paint1_linear_329_251" x1="366.807" y1="88.5987" x2="415.646" y2="88.4949" gradientUnits="userSpaceOnUse">
<stop stop-color="#FD6727"/>
<stop offset="0.84375" stop-color="#FD9B27"/>
</linearGradient>
<linearGradient id="paint2_linear_329_251" x1="469.289" y1="197.242" x2="528.279" y2="197.198" gradientUnits="userSpaceOnUse">
<stop stop-color="#FD6727"/>
<stop offset="0.84375" stop-color="#FD9B27"/>
</linearGradient>
<linearGradient id="paint3_linear_329_251" x1="497.886" y1="112.328" x2="531.286" y2="112.283" gradientUnits="userSpaceOnUse">
<stop stop-color="#FD6727"/>
<stop offset="0.84375" stop-color="#FD9B27"/>
</linearGradient>
<linearGradient id="paint4_linear_329_251" x1="233.029" y1="206.305" x2="263.06" y2="206.26" gradientUnits="userSpaceOnUse">
<stop stop-color="#FD6727"/>
<stop offset="0.84375" stop-color="#FD9B27"/>
</linearGradient>
<linearGradient id="paint5_linear_329_251" x1="350.811" y1="119.92" x2="410.873" y2="119.868" gradientUnits="userSpaceOnUse">
<stop stop-color="#FD6727"/>
<stop offset="0.84375" stop-color="#FD9B27"/>
</linearGradient>
<linearGradient id="paint6_linear_329_251" x1="362.597" y1="100.03" x2="410.883" y2="99.9217" gradientUnits="userSpaceOnUse">
<stop stop-color="#FD6727"/>
<stop offset="0.84375" stop-color="#FD9B27"/>
</linearGradient>
<linearGradient id="paint7_linear_329_251" x1="475.045" y1="121.349" x2="490.061" y2="121.322" gradientUnits="userSpaceOnUse">
<stop stop-color="#FD6727"/>
<stop offset="0.84375" stop-color="#FD9B27"/>
</linearGradient>
<clipPath id="clip0_329_251">
<rect width="769" height="321" fill="white"/>
</clipPath>
</defs>
</svg>
`;

const arch = `<svg xmlns="http://www.w3.org/2000/svg" width="71" height="24" viewBox="0 0 71 24" fill="none">
  <path d="M3.94141 20.7805C7.52414 15.6535 12.3021 11.4765 17.8619 8.61107C23.4217 5.7456 29.5959 4.27787 35.8504 4.33485C42.1049 4.39183 48.2514 5.97181 53.7581 8.9381C59.2647 11.9044 63.9658 16.1677 67.4545 21.3591" stroke="#E2A21F" stroke-width="8"/>
</svg>`;

const ribbon = ` <svg xmlns="http://www.w3.org/2000/svg" width="63" height="84" viewBox="0 0 63 84" fill="none">
  <path d="M6.11035 70.6426L26.6007 65.1522L21.1104 44.6618L41.6007 39.1714L36.1103 18.6811L56.6007 13.1907" stroke="#00A7E5" stroke-width="8" stroke-linecap="round" stroke-linejoin="round"/>
</svg>`;

const circle = `<svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" viewBox="0 0 56 56" fill="none">
  <path d="M52 27.8362C52 40.7533 41.3219 51.3449 28 51.3449C14.6781 51.3449 4 40.7533 4 27.8362C4 14.9191 14.6781 4.32739 28 4.32739C41.3219 4.32739 52 14.9191 52 27.8362Z" stroke="#E40173" stroke-width="8"/>
</svg>`;

const triangle = `<svg xmlns="http://www.w3.org/2000/svg" width="42" height="37" viewBox="0 0 42 37" fill="none">
  <path d="M20.9999 0.333252L41.7845 36.3333H0.215332L20.9999 0.333252Z" fill="#00CC67"/>
</svg>`;

const badge = `<svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" viewBox="0 0 56 56" fill="none">
  <path d="M28 0L52.2487 14V42L28 56L3.75129 42V14L28 0Z" fill="#D9DBE9"/>
</svg>`;

const square = `<svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
  <rect width="40" height="40" fill="#D9DBE9"/>
</svg>`;

const dot = `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
  <circle cx="12" cy="12" r="12" fill="#C19CFC"/>
</svg>`;

const svg_icons = [
  {
    name: arch,
    class:
      "top-20 right-3    lg:top-[20%] lg:right-1/4 lg:left-auto md:bottom-auto",
    size: "w-[56px] h-[18px] lg:w-[71px] lg:h-[24px]",
  },
  {
    name: badge,
    class: "lg:top-1/3 lg:right-auto lg:left-10 md:bottom-auto",
    size: "w-14 h-14",
  },
  {
    name: dot,
    class: "lg:top-1/3 lg:right-auto lg:left-1/4 md:bottom-auto dot",
    size: "w-6 h-6",
  },
  {
    name: triangle,
    class: "lg:top-28 lg:right-auto lg:left-1/4 md:bottom-auto",
    size: "w-[42px] h-[37px]",
  },
  { name: ribbon, class: "lg:top-1/2 lg:right-20 lg:left-auto md:bottom-auto" },
  { name: circle, class: "md:bottom-5 lg:right-2" },
  {
    name: square,
    class: "lg:top-auto lg:right-auto lg:left-28 md:bottom-28",
    size: "w-10 h-10",
  },
];

const divRef = ref(null);
const { width, height } = useElementSize(divRef);
// const {width, height} = useWindowSize()
const anime = useNuxtApp().$anime;
function onEnter() {
  // anime({
  //   targets: '.cySvg svg',
  //   translateX: [0, 200],
  //   easing: "easeInOutElastic",
  //   delay: 2000,
  //   rotate: {
  //     value: 360,
  //     duration: 1000,
  //   },
  // });
  //   anime.set(".cySvg svg", {
  //     translateX: function () {
  //       return anime.random(Math.floor(0.9 * width.value), width.value);
  //     },
  //     translateY: function () {
  //       return anime.random(Math.floor(0.9 * height.value), width.value);
  //     },
  //   });
  // }
  // function onLeave(el: any, done: any) {
  //   // call the done callback to indicate transition end
  //   // optional if used in combination with CSS
  //   anime({
  //     targets: el,
  //     easing: "easeInOutElastic",
  //     delay: 200,
  //     complete: done,
  //   });
}

// function onBeforeEnter(el: any) {
//   anime.set(el, {
//     scaleX: 0.25,
//     scaleY: 0.25,
//     opacity: [0, 1],
//     duration: 500,
//   });
// }

// onMounted(() => {
//   onEnter();
//   const anime = useNuxtApp().$anime;
//   var path = anime.path(".motor .smoke");
//   anime({
//     targets: ".cySvg svg",
//     rotate: {
//       value: [0, 360],
//       // duration: 3000,
//       easing: "easeInOutSine",
//     },
//     easing: "easeInOutQuad",
//     duration: 7000,
//     loop: true,
//   });
//   var smokeAnime = anime.timeline({
//     easing: "easeInOutQuad",
//     duration: 2000,
//     endDelay: 500,
//     loop: true,
//     direction: "alternate",
//   });
//   smokeAnime.add(
//     {
//       targets: ".motor .smoke",
//       fill: colors.gray[300],
//     },
//     0
//   );
// });
</script>

<template>
  <div>
    <div ref="divRef" class="w-full flex flex-col justify-between items-center h-full text-center relative">
      <span v-for="item of svg_icons" class="absolute hidden lg:inline-block" :class="item.class">
        <span v-html="item.name" class="absolute cySvg" :class="item.class" />
      </span>
      <!-- <div class=""></div> -->
      <div class="sm:pt-24 flex w-full flex-col justify-center items-center gap-4">
        <div class="py-6 sm:py-14">
          <Logo size="2xl" />
        </div>
        <div>
          <h1 class="text-2xl sm:text-4xl font-medium leading-normal uppercase">
            we are coming soon!!!
          </h1>
          <p class="text-sm sm:text-base">Stay tuned for something amazing</p>
        </div>
        <div>
          <span v-html="underconstruction" class="[&>svg]:w-52 [&>svg]:h-auto [&>svg]:sm:w-96 [&>svg]:md:w-fit motor" />
        </div>
      </div>
      <div class="p-4">
        <ClientOnly>
          <span> &copy; Copyrights Cybandy | All Rights Reserved</span>
        </ClientOnly>
      </div>
    </div>
  </div>
</template>

<style scoped>
</style>
